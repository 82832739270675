
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { VCol, VRow } from 'vuetify/lib/components'

@Component({
  components: {
    VRow,
    VCol,
    TimelineText: () => import('./GTimelineItemText.vue'),
    TimelinePhoto: () => import('./GTimelineItemPhoto.vue'),
    TimelineEmail: () => import('./GTimelineItemEmail.vue'),
    TimelinePhone: () => import('./GTimelineItemPhone.vue'),
  },
})
  export default class GTimelineItem extends Vue {
  @Prop({ type: Object }) log!: any
  @Prop({ type: String }) person!: string

  get backgroundColor () {
    const { log: { action } } = this

    if (action === 'creó') return 'green lighten-1'

    return action === 'editó' ? 'blue lighten-2' : 'red lighten-1'
  }

  render (h) {
    const pItems = this.log.items.map((item: any, i: number) => {
      const componentName = item.component || 'TimelineText'
      return h(
        'v-col',
        {
          props: {
            cols: 12,
          },
        }, [h(
          componentName,
          {
            class: ['pa-0'],
            key: i,
            props: {
              value: item.value,
              label: item.label + ':',
            },
          },
          [item.label]
        )])
    })

    return h(
      'v-row',
      {
        class: [this.backgroundColor, 'rounded-lg', 'pa-2'],
        style: { wordBreak: 'break-word' },
        props: { noGutters: true },
      },
      [
        h(
          'p',
          {
            class: [
              'text-caption',
              'primary-text',
              'text--darken-4',
              'secondary',
              'rounded-lg',
              'pa-0',
              'pb-2',
              this.backgroundColor,
            ],
          },
          [h('p', { class: ['text-caption', 'primary-text', 'text--darken-4', 'font-weight-bold'] }, this.log.date), ' ', this.person, ' ', this.log.action]
        ),
        ...pItems,
      ]
    )
  }
  }
